import {initHandleNaprFormListeners} from '@js/components/captcha';

$(() => {
    materialInputHandlers();
    initHandleNaprFormListeners();
    const $clientForm = $('form.auth-form__inner');
    let role = '';
    const roleInput = $('input[name="role"]');
    if (roleInput.length) {
        role = roleInput.val();
    }
    ;
    replaceUriInULoginWidget($clientForm, role.val());
});

function materialInputHandlers() {
    $('body')
        .on('mousedown', '.material-input__form-control_password-control', function () {
            const $passwordInput = $('input[name="password"]');
            $passwordInput.attr('type', 'text');
            $(this).find('i').removeClass('fa-eye');
            $(this).find('i').addClass('fa-eye-slash');
        })
        .on('mouseup', '.material-input__form-control_password-control', function () {
            const $passwordInput = $('input[name="password"]');
            $(this).find('i').addClass('fa-eye');
            $(this).find('i').removeClass('fa-eye-slash');
            $passwordInput.attr('type', 'password');
        });
    $('.auth-form__role-block')
        .on('click', '.auth-form__btn', function () {
            $('.auth-form__back-to-role').show();
            const $vuzForm = $('.auth-form__integrated-section');
            const $clientForm = $('form.auth-form__inner');
            if ($(this).attr('id') === 'oo') {
                $clientForm.hide();
                $vuzForm.show();
            } else {
                const role = $(this).attr('data-role');
                $('input[name="role"]').val(role);
                replaceUriInULoginWidget($clientForm, role);
                $clientForm.removeClass('hidden');
                $clientForm.show();
                $vuzForm.hide();
            }
            $('.auth-form__role-block').hide();
        });
    $('.auth-form__back-to-role')
        .on('click', '.auth-form__btn-link', () => {
            $('.auth-form__back-to-role').hide();
            $('.auth-form__role-block').show();
            $('.auth-form__integrated-section').hide();
            $('form.auth-form__inner').hide();
        });
}

function replaceUriInULoginWidget($form, role) {
    const $uLogin = $('#uLogin');
    let dataUlogin = $uLogin.attr('data-ulogin');
    let pattern = new RegExp("redirect_uri=(.*?);");
    const redirectUri = pattern.exec(dataUlogin)[1];
    let delimiter = '';
    if (redirectUri[redirectUri.length - 1] !== '/') {
        delimiter = '/';
    }
    const newRedirectUri = `${redirectUri}${delimiter}${role}`;
    dataUlogin = dataUlogin.replace(redirectUri, newRedirectUri);
    $uLogin.attr('data-ulogin', dataUlogin);
    $uLogin.removeAttr('data-ulogin-inited');
    $uLogin.clone().appendTo($form);
    $uLogin.remove();
}
