import {sendRequest} from '@js/utils';
import {routes} from '@js/helpers/routes';

export function initHandleNaprFormListeners() {
    $('.auth-page__form').on('click', '#reload', () => {
        const location = routes.lk.captcha.reloadCaptcha();
        sendRequest('get', `${location}`, '', (res) => {
            if (!res?.success) {
                $('.captcha span').html(res.captcha);
            }
        });
    });
}
